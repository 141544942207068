<template>
  <div class="about-me">
    <div class="container flex justify-center content-center direction-column h-100">
      <PageTitle :title="'O mnie'"/>
    </div>
  </div>
  <div class="section">
    <div class="container flex vertical-center">
      <div class="w-50">
        <Header :title="'Kilka słów na początek'"/>
        <div v-if="content.aboutMe">
          <div v-html="content.aboutMe"></div>
        </div>
      </div>
      <div class="w-50">
        <img :src="require(`@/assets/programmer.svg`)" alt="" class="img--full mt-3">
      </div>
    </div>
  </div>
  <div class="section bg--invert">
    <div class="container flex vertical-center">
      <div class="w-50">
        <img :src="require(`@/assets/codes.svg`)" alt="Logo 300.codes" class="img--full mt-3">
      </div>
      <div class="w-50">
        <Header :title="'Aktualna praca'" :invert="true"/>
        <div v-if="content.job" class="text--invert">
          <div v-html="content.job"></div>
        </div>
      </div>
    </div>
  </div>
  <div class="section">
    <div v-if="technologies" class="container flex vertical-start">
      <div class="w-50">
        <Header :title="'Stack technologiczny'"/>
        <div v-if="content.stack">
          <div v-html="content.stack"></div>
        </div>
      </div>
      <div class="w-50">
        <div v-for="(category, index) in technologies" :key="index"
        class="mt-3 flex justify-center">
          <div class="badge__element display--inline-block text--center--sm">
            <p class="badge__header">{{ category.name }}</p>
          </div>
          <div class="technology__list">
            <Swiper
              :slides-per-view="2"
              :space-between="20"
              :autoplay="{ delay: 1750, reverseDirection: true }"
              :modules="[Pagination, Autoplay]"
              :breakpoints="{
                768: {
                  slidesPerView: 3,
                  spaceBetween: 30,
                }
              }"
            >
              <SwiperSlide  v-for="technology in category.items" :key="technology">
                <a :href="technology.link"
                  :class="{ 'u-events--none': !technology.link }"
                  target="_blank">
                  <img :src="technology.img" class="technology__img">
                </a>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { Swiper, SwiperSlide } from 'swiper/vue/swiper-vue';
import { Pagination, Autoplay } from 'swiper';
import 'swiper/swiper-bundle.css';
import 'swiper/modules/pagination/pagination.min.css';
import store from '../store';
import PageTitle from '../components/PageTitle';
import Header from '../components/Header';

export default {
  name: 'About',
  components: {
    PageTitle, Header, Swiper, SwiperSlide,
  },
  setup() {
    const technologies = ref([]);
    const content = ref({ aboutMe: '', job: '', stack: '' });

    onMounted(async () => {
      await store.dispatch('getTechnologies');
      technologies.value = store.getters.technologies;
      const pageContent = await store.dispatch('getPageContent', 145);
      content.value.aboutMe = pageContent.aboutMe;
      content.value.job = pageContent.job;
      content.value.stack = pageContent.stack;
    });

    return {
      technologies, content, Pagination, Autoplay,
    };
  },
};
</script>

<style lang="scss" scoped>
@import '../scss/vars';
.about-me {
  background: url('../assets/hero_project.jpg') no-repeat;
  background-size: cover;
  padding-top: 8rem;
  padding-bottom: $padding-4;

  @media #{$lg-min}  {
    background-position-y: -200px;
  }
}
.technology {
  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    overflow: hidden;
  }

  &__img {
    width: auto;
    height: 75px;
  }
}
.u-events--none {
  pointer-events: none;
}
</style>
